import React, { useState } from "react";
import "../theme.css";

export default class SingleSelection extends React.Component{
  state = {
    submitting:false
  }
  render(){
    return(
      <div class="form-group">
        <label class="sr-only" for="reservation__form__people">{this.props.title}</label>
        <p>{this.props.title}</p>
        <select class="form-control" id="reservation__form__people" name="reservation__form__people" onChange={this.props.onChange} multiple={this.props.multiple}>
          {this.props.options.map((item)=>{
            return(
              <option value={item.name}>{item.name} {item.price?"$"+(item.price/100).toFixed(2):""}</option>
            )
          })
          }
        </select>
        <div class="invalid-feedback"></div>
      </div>
    )
  }
}
