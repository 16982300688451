export const getMenu = `query GetCustomer($id:ID!) {
  getCustomer(id: $id) {
    id
    openHours
    merchantMenus(limit:30) {
      nextToken
      items {
        category
        createdAt
        name
        id
        menuItems(limit:30) {
          items {
            id
            name
            description
            price
            options
          }
        }
      }
    }
  }
}
`

export const getPendingOrder = `query GetPendingOrder($id:ID!) {
  getPendingOrder(id: $id) {
    id
    paymentMethod
    location
    orderInformation
    user
    approved
    deliveryQuote
  }
}`
