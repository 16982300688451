import React, { useState } from "react";
import "../theme.css";

export default class Button extends React.Component{
  state = {
    submitting:false
  }
  render(){
    return(
      <div class="col" style={this.props.style}>
        <div class="text-center">
          <button type="submit" class="btn btn-primary" disabled={this.state.submitting} onClick={this.props.onClick} style={{width:175}}>
            {(this.state.submitting)?<div class="loader"></div>:this.props.text}
          </button>
        </div>
      </div>
    )
  }
}
