import React, { useState } from "react";
import "../theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "../dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation} from "../mutations.js"
import {Parallax} from 'react-parallax'

const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined

export default class Form extends React.Component{
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    reservationDate:Math.floor(new Date().now / 1000),
    reservationTime:thisHour,
    seatingArea:"Outdoors",
    numberOfPeople:1
  }
  async handleSubmit(event){
    event.preventDefault();
    this.setState({submitting:true})
    let {firstName,lastName,phoneNumber,emailAddress,numberOfPeople,reservationDate,reservationAddedSeconds} = this.state
    const requestedTimeStamp = parseInt(reservationDate)+parseInt(reservationAddedSeconds/1000)
    const input = {
      firstName:firstName,
      lastName:lastName,
      phoneNumber:"+1"+phoneNumber,
      emailAddress:emailAddress,
      numberOfPeople:numberOfPeople,
      confirmed:false,
      requestedTimeStamp:requestedTimeStamp,
      merchantId:this.props.user.id
    }
    console.log(input)
    var res = await API.graphql(graphqlOperation(createReservation,{input}))
    this.setState({submitting:false,madeReservation:true,firstName:"",lastName:"",phoneNumber:"",emailAddress:"",numberOfPeople:""})
    console.log(res)
  }
  handleSubmit(){
  }
  render(){
    return(
      <>

    	<section class="section section_header about-us" data-parallax="scroll">
    		<div class="container">
    			<div class="row">
    				<div class="col">

    					<h1 class="section__heading section_header__heading text-center">
    						About Us
    					</h1>

    				</div>
    			</div>
    		</div>
    	</section>

    	<section class="section section_discover">
    		<div class="container">
    			<div class="row">
    				<div class="col-md-2 align-self-start">

    					<p class="section_discover__description">
    						Made in New York, NY
    					</p>

    				</div>
    				<div class="col-md-4 align-self-start">

    					<div class="section_discover__img">
    						<img src="assets/img/2.jpg" class="img-fluid" alt="..."/>
    					</div>

    				</div>
    				<div class="col-md-6 align-self-center">

    					<h2><em>New York Blast</em> An Authentic NYC Brand</h2>
    					<p>
    						New York Blast is grown in New York City. We keep it fresh.
    					</p>
    					<p>
                We support our local community by investing in historically disadvantaged communities.
    					</p>
    					<p>
    						When you take a hit of New York Blast, you can feel good about where it's coming from.
    					</p>

    				</div>
    			</div>
    		</div>
    	</section>

    	<section class="section section_quote section_gray">
    		<div class="container section_quote__container">
    			<div class="row">
    				<div class="col col-md-8 ml-md-auto mr-md-auto">

    					<blockquote class="section_quote__blockquote">
    						<p class="text-center">
    							One cannot think well, love well, sleep well, if one has not dined well.
    						</p>
    						<footer class="text-center">
    							Virginia Woolf
    						</footer>
    					</blockquote>

    				</div>
    			</div>
    		</div>
    	</section>

    	<section class="section section_info section_info_opposite">
    		<div class="container">
    			<div class="row">
    				<div class="col">

    					<h2 class="section__heading text-center">
    						Behind the scenes
    					</h2>

    					<p class="section__subheading text-center">
    						Behind the scenes at New York Blast there is a team of hard working people.
    					</p>

    				</div>
    			</div>
    			<div class="row align-items-justify">
    				<div class="col-md-6 order-md-3">

    					<div class="section_info__img">
    						<img src="assets/img/24.jpg" alt="..."/>
    					</div>

    				</div>
    				<div class="col-md-1 order-md-2"></div>
    				<div class="col-md-5 order-md-1">
    					<div class="section_info__body">
    						<p class="lead text-heading">
    							Come enjoy our farm-grown products
    						</p>
    						<p>
    							Grown from our Pennsylvania farm and sent directly to our kitchen. Doesn't get fresher than that.
    						</p>
    						<br/>
    						<p>
    							<a href="/contact-us" class="btn btn-primary">
    								Contact Us
    							</a>
    						</p>
    					</div>

    				</div>
    			  </div>
    		  </div>
    	</section>
      </>
    )
  }
}
