import React, { useState } from "react";
import "../theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "../dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation} from "../mutations.js"

const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined

export default class Form extends React.Component{
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    reservationDate:Math.floor(new Date().now / 1000),
    reservationTime:thisHour,
    seatingArea:"Outdoors",
    numberOfPeople:1
  }
  async handleSubmit(event){
    event.preventDefault();
    this.setState({submitting:true})
    let {firstName,lastName,phoneNumber,emailAddress,numberOfPeople,reservationDate,reservationAddedSeconds} = this.state
    const requestedTimeStamp = parseInt(reservationDate)+parseInt(reservationAddedSeconds/1000)
    const input = {
      firstName:firstName,
      lastName:lastName,
      phoneNumber:"+1"+phoneNumber,
      emailAddress:emailAddress,
      numberOfPeople:numberOfPeople,
      confirmed:false,
      requestedTimeStamp:requestedTimeStamp,
      merchantId:this.props.user.id
    }
    console.log(input)
    var res = await API.graphql(graphqlOperation(createReservation,{input}))
    this.setState({submitting:false,madeReservation:true,firstName:"",lastName:"",phoneNumber:"",emailAddress:"",numberOfPeople:""})
    console.log(res)
  }
  render(){
    return(
      <section class="section section_reservation section_gray" id="section_reservation" style={{marginTop:30}}>
        <div class="container">
          <div class="row">
            <div class="col">

              <h2 class="section__heading text-center">
              Make an online reservation
              </h2>

              <p class="section__subheading text-center">
                Make an online reservation. When your seat is confirmed we will send you a confirmation text message or email.
              </p>

            </div>

          </div>
          <div class="row justify-content-lg-center  section_reservation__row">
            <div class="col-lg-8">

              <form class="section_reservation__form" id="reservation__form" onSubmit={this.handleSubmit}>
                <div class="row">
                  <div class="col-md-6">
                    {
                      this.props.fields.map((item,index)=>{
                        return(
                          <div class="form-group">
                            <label class="sr-only" for="reservation__form__name">{item.title}</label>
                            <input required title="Please insert your number with no spaces or dashes XXXXXXXXXX" pattern={(item.pattern)?item.pattern:"*"} type={(item.type)?item.type:"text"} class="form-control" id="reservation__form__name" name="reservation__form__name" placeholder={item.title} onChange={(e)=>{this.setState({[item.label]:e.target.value});}} value={this.state[item.label]}/>
                            <div class="invalid-feedback"></div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="sr-only" for="reservation__form__people">Seating Option</label>
                      <select class="form-control" id="reservation__form__people" name="reservation__form__people" onChange={(e)=>this.setState({seatingArea:e.target.value})}>
                        <option value="indoor">Indoors</option>
                        <option value="outdoor">Outdoors</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="sr-only" for="reservation__form__people">People</label>
                      <select class="form-control" id="reservation__form__people" name="reservation__form__people" onChange={(e)=>this.setState({numberOfPeople:e.target.value})}>
                        <option value="1">1 person</option>
                        <option value="2" selected="">2 people</option>
                        <option value="3">3 people</option>
                        <option value="4">4 people</option>
                        <option value="5">5 people</option>
                      </select>
                      <div class="invalid-feedback"></div>
                    </div>
                    <div class="form-group">
                      <label class="sr-only" for="reservation__form__date">Date</label>
                      <DayPickerSingleDateControllerWrapper
                        date={this.state.date} // momentPropTypes.momentObj or null
                        onDateChange={date => this.setState({ reservationDate:date })} // PropTypes.func.isRequired
                        focused={this.state.focused} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                        id="your_unique_id" // PropTypes.string.isRequired,
                      />
                      <div class="invalid-feedback"></div>
                    </div>
                    <div class="form-group">
                      <label class="sr-only" for="reservation__form__time">Time</label>
                      <input required title="Sorry, we're only open from 10AM-8PM" type="time" min="10:00" max="21:00" class="form-control" id="reservation__form__time" name="reservation__form__time" value={this.state.reservationTime} onChange={(e)=>{this.setState({reservationTime:e.target.value,reservationAddedSeconds:e.target.valueAsNumber})} }/>
                      <div class="invalid-feedback"></div>
                    </div>

                  </div>
                  <div class="col">
                    {this.state.madeReservation&&<div class="alert alert-success">
                      Congratulations you made a succesful reservation! You will receive a text message as soon as our staff confirms your spot.
                    </div>}
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary" disabled={this.state.submitting}>
                        {(this.state.submitting)?<div class="loader"></div>:"Reserve a table"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </section>
    )
  }
}
