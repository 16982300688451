import React, { useState } from "react";
import "../theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "../dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation} from "../mutations.js"
import {Parallax} from 'react-parallax'
import {getCorrectOptionsString} from "../functions/js/simpleCalcs.js"
import SingleSelection from "./SingleSelection.js";
import Button from "./button.js";
import Masonry from 'react-masonry-component';

const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined

export default class ItemList extends React.Component{
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
  }
  removeMenuItem(index){
    var removedItem = this.props.shoppingCart[index];
    this.props.shoppingCart.splice(index,1);
    var shoppingCartLength = this.props.shoppingCart.length;
    var oldTotalItem = this.props.shoppingCart[shoppingCartLength-1];
    var newTotal = parseInt(oldTotalItem.summedAmount - parseInt(removedItem.summedAmount*1.0875));
    this.props.shoppingCart[shoppingCartLength-1].summedAmount=newTotal;
    this.props.shoppingCart[shoppingCartLength-2].summedAmount=parseInt(newTotal*0.0875);
    this.props.setState({shoppingCart:this.props.shoppingCart});
  }
  async handleSubmit(event){
    event.preventDefault();
    this.setState({submitting:true})
    let {firstName,lastName,phoneNumber,emailAddress,numberOfPeople,reservationDate,reservationAddedSeconds} = this.state
    const requestedTimeStamp = parseInt(reservationDate)+parseInt(reservationAddedSeconds/1000)
    const input = {
      firstName:firstName,
      lastName:lastName,
      phoneNumber:"+1"+phoneNumber,
      emailAddress:emailAddress,
      numberOfPeople:numberOfPeople,
      confirmed:false,
      requestedTimeStamp:requestedTimeStamp,
      merchantId:this.props.user.id
    }
    console.log(input)
    var res = await API.graphql(graphqlOperation(createReservation,{input}))
    this.setState({submitting:false,madeReservation:true,firstName:"",lastName:"",phoneNumber:"",emailAddress:"",numberOfPeople:""})
    console.log(res)
  }
  handleSubmit(){
  }
  render(){
    return(
            <div
            className="section_menu__grid"
            style={{justifyContent:"center",display:"flex",flexDirection:"column",alignItems:"center"}}
            >
            {this.props.shoppingCart.map((menuItem,index)=>
              <div key={menuItem.id} className="col-md-6 section_menu__grid__item" style={{marginTop:10}}>
                <div className="section_menu_item">
                  <div class="row">
                    {(!this.props.hideImages)&&<div class="col-3 align-self-center">
                      <div class="section_menu__item__img">
                        <img src={(menuItem.image)?menuItem.image:`assets/img/${[25+(index%7)]}.jpg`} alt="..."/>
                      </div>
                    </div>}
                    <div class="col-7">
                      <h4>{menuItem.amount} {menuItem.details.name}</h4>
                      <p>
                        {menuItem.details.description}
                      </p>
                      <p>
                        {getCorrectOptionsString(menuItem)}
                      </p>
                      {(!menuItem.notRemovable)&&<Button text="Remove" style={{marginTop:10}} onClick={()=>this.removeMenuItem(index)}/>}
                    </div>
                    <div class="col-2">
                      <div class="section_menu__item__price text-center">
                        ${(menuItem.summedAmount/100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
            }
            </div>
    )
  }
}

// (item,index)=>
//   <div key={item.name+index} className={`col-md-6 section_menu__grid__item ${item.filter}`}>
//   <div class="section_menu__item">
//       <div class="row">
//         <p allowFontScaling={false}>
//           {item.amount} {(item.amount>1)?item.details.name+"s":item.details.name}{"\n"}
//         </p>
//         {
//           (item.options!==undefined&&item.options!==null)&&
//           <p allowFontScaling={false}>
//           {getCorrectOptionsString(item)}
//             </p>
//         }
//         {(item.note!==undefined)&&(item.note!==null)&&(item.note!=="")&&
//           <p allowFontScaling={false}>
//             Note: {item.note}{"\n"}
//           </p>
//         }
//     </div>
//     <div style={{flexDirection:"column",marginLeft:20}}>
//       <p allowFontScaling={false}>
//         ${(item.summedAmount/100).toFixed(2)}
//       </p>
//       <div style={{flexDirection:"row",alignItems:"space-between"}}>
//         <div style={{height:50,width:50,backgroundColor:"#7634E9",borderRadius:25,justifyContent:"center",alignItems:"center",marginRight:10}} onPress={()=>this.editMenuItem(item,index)}>
//           <i className="fab fa-pencil" style={{fontSize:30,color:"white"}}/>
//         </div>
//       {(!item.notRemovable)&&(!this.props.openTablediv)&&
//         <div style={{height:50,width:50,backgroundColor:"#7634E9",borderRadius:25,justifyContent:"center",alignItems:"center"}} onPress={()=>this.props.removeMenuItem(item,index)}>
//           <i name="fab fa-trash" style={{fontSize:30,color:"white"}}/>
//         </div>
//       }
//       </div>
//     </div>
//   </div>
//   </div>)
