import React, { useState } from "react";
import "../theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "../dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation} from "../mutations.js"
import ReactCardFlip from 'react-card-flip';
import PaymentMethods from "./PaymentMethods";
import {createPendingOrder} from "../mutations.js"
import {onUpdatePendingOrder} from "../subscriptions.js"
import {getShoppingCartWithoutImages} from "../functions/js/simpleCalcs.js";

const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined

export default class Fields extends React.Component{
  constructor(props){
    super(props);
    this.flipCard = this.flipCard.bind(this)
  }
  state={
    shoppingCart:this.props.shoppingCart,
    alreadyCreatedRecord:false
  }
  async flipCard(event){
    event.preventDefault();
    this.setState({isFlipped:!this.state.isFlipped});
    let {firstName,lastName,phoneNumber,emailAddress,address1,address2,shoppingCart,city,state,zipCode} = this.state
    const input = {
      location:JSON.stringify({
        address1:address1,
        address2:address2,
        city:city,
        state:state,
        zipCode:zipCode
      }),
      user:JSON.stringify({
        firstName:firstName,
        lastName:lastName,
        emailAddress:emailAddress,
        phoneNumber:"+1"+phoneNumber
      }),
      orderInformation:JSON.stringify(getShoppingCartWithoutImages(shoppingCart)),
      merchantId:this.props.user.id,
      approved:false
    }
    console.log(input)
    var res = await API.graphql(graphqlOperation(createPendingOrder,{input})).catch(err=>console.log(err))
    var subscription = API.graphql(
      graphqlOperation(onUpdatePendingOrder,{id:res.data.createPendingOrder.id})
    ).subscribe({
      next: ({provider,value}) => {
        console.log({provider,value});
        this.setState({deliveryQuote:JSON.parse(value.data.onUpdatePendingOrder.deliveryQuote)})
      }
    })
    this.setState({pendingOrderId:res.data.createPendingOrder.id})
  }
  render(){
    return(
      <section class="section section_reservation section_gray" id="section_reservation" style={{marginTop:30}}>
        <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="vertical" className="row">
        <div class="container">
          <div class="row">
            <div class="col">

              <h2 class="section__heading text-center">
              Delivered directly from New York Blast
              </h2>

              <p class="section__sukbheading text-center">
                Please input your address details below {/*or <a href="https://www.kikt.io">use Kikt</a>*/}
              </p>

            </div>

          </div>
          <div class="row justify-content-lg-center  section_reservation__row">
            <div class="col-lg-8">

              <form class="section_reservation__form" id="reservation__form" onSubmit={this.flipCard}>
                <div class="row">
                  <div class="col-md-6">
                    {
                      this.props.fields.slice(0,4).map((item,index)=>{
                        return(
                          <div class="form-group">
                            <label class="sr-only" for="reservation__form__name">{item.title}</label>
                            <input required={(item.required!==undefined)?(item.required):true} title="Please insert your number with no spaces or dashes XXXXXXXXXX" pattern={(item.pattern)?item.pattern:"*"} type={(item.type)?item.type:"text"} class="form-control" id="reservation__form__name" name="reservation__form__name" placeholder={item.title} onChange={(e)=>{this.setState({[item.label]:e.target.value});}} value={this.state[item.label]}/>
                            <div class="invalid-feedback"></div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div class="col-md-6">
                    {
                      this.props.fields.slice(4).map((item,index)=>{
                        return(
                          <div class="form-group">
                            <label class="sr-only" for="reservation__form__name">{item.title}</label>
                            <input required={(item.required!==undefined)?(item.required):true} title="Please insert your number with no spaces or dashes XXXXXXXXXX" pattern={(item.pattern)?item.pattern:"*"} type={(item.type)?item.type:"text"} class="form-control" id="reservation__form__name" name="reservation__form__name" placeholder={item.title} onChange={(e)=>{this.setState({[item.label]:e.target.value});}} value={this.state[item.label]}/>
                            <div class="invalid-feedback"></div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div class="col">
                    {this.state.madeReservation&&<div class="alert alert-success">
                      Congratulations you made a succesful reservation! You will receive a text message as soon as our staff confirms your spot.
                    </div>}
                    <div class="text-center" style={{marginTop:30}}>
                      <button class="btn btn-primary" disabled={this.state.submitting}>
                        {(this.state.submitting)?<div class="loader"></div>:"Select Payment Method"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
        <PaymentMethods
          fields={
            [{title:"Address",label:"address1"}]
          }
          setState={(props)=>this.setState(props)}
          deliveryQuote={this.state.deliveryQuote}
          totalCharged={this.props.totalCharged}
          pendingOrderId={this.state.pendingOrderId}
        />
        </ReactCardFlip>
      </section>
    )
  }
}
