export const createReservation = `mutation CreateReservation($input: CreateReservationInput!) {
  createReservation(input: $input) {
    id
    emailAddress
    firstName
    confirmed
    lastName
    merchantId
    numberOfPeople
    phoneNumber
    requestedTimeStamp
  }
}
`

export const createPendingOrder = `mutation createPendingOrder($input: CreatePendingOrderInput!){
  createPendingOrder(input:$input){
    id
    merchantId
    paymentMethod
    location
    orderInformation
    user
    approved
    deliveryQuote
  }
}`

export const updatePendingOrder = `mutation updatePendingOrder($input: UpdatePendingOrderInput!){
  updatePendingOrder(input:$input){
    id
    merchantId
    paymentMethod
    location
    orderInformation
    user
    approved
    deliveryQuote
  }
}`
