

export const onUpdatePendingOrder = `subscription OnUpdatePendingOrder($id:ID!) {
  onUpdatePendingOrder(id: $id) {
    id
    paymentMethod
    location
    orderInformation
    user
    approved
    deliveryQuote
  }
}`
