import React, { Component } from "react";

export default class IconWithBadge extends Component {

  render() {
    return (
        <div style={this.props.style}>
          {(this.props.badgeValue>0)&&<div style={{backgroundColor:"#f5a637",
          borderRadius:10,
          width:20,
          height:20,
          alignItems:'center',
          justifyContent:'center',
          position:"absolute",
          top:-10,
          right:-18,
          display:"flex"}}>
            <p style={{color:"white",fontSize:10,display:"flex",alignSelf:"center",top:5,position:"relative"}}>
              {this.props.badgeValue}
            </p>
          </div>}
          <i class={this.props.iconName} style={{color:"#777"}}/>
        </div>
    )
  }
}
