import React from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  generatePath
} from "react-router-dom";
import "../theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "../dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation,createPendingOrder} from "../mutations.js"
import {Parallax} from 'react-parallax'
import ItemList from "./ItemList.js"
import Button from "./button.js"
import Form from "./Form.js";
import Menu from "../Menu.js";
import Fields from "./Fields.js";
import PickUpFields from "./PickUpFields.js";
import {getAllOptionPrices} from "../functions/js/simpleCalcs.js";


const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined
let blankImage = `data:image/jpeg;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`


export default class Checkout extends React.Component{
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.query = useQuery()
  }
  state = {
    reservationDate:Math.floor(new Date().now / 1000),
    reservationTime:thisHour,
    seatingArea:"Outdoors",
    numberOfPeople:1,
    loading:true
  }
  async componentDidMount(){
    try{
      window.scrollTo(0, 0)
      const shoppingCart = await ((new URLSearchParams(window.location.search)).get("cart"))
      var parsedShoppingCart = JSON.parse(shoppingCart)
      var totalCharged = 0
      for (var i = 0; i < parsedShoppingCart.length; i ++){
        var _menuItem = parsedShoppingCart[i]
        totalCharged += _menuItem.summedAmount
        if (_menuItem.options){
          if ( (JSON.stringify(_menuItem.options).indexOf("$") > -1 ) || ( JSON.stringify(_menuItem.options).indexOf("price") > -1 ) ){
            // If we find $ or price we know that the options cost some amount of money
            var additionalOptionCost = parseInt(getAllOptionPrices(_menuItem.options)*_menuItem.amount)
            totalCharged+=additionalOptionCost
            _menuItem.summedAmount += additionalOptionCost
            parsedShoppingCart[i]=_menuItem
          }
        }
      }
      const taxAmount = parseInt(totalCharged*0.0875)
      totalCharged+=taxAmount
      const totalPriceItem = {summedAmount:totalCharged,description:"",amount:"",details:{name:"Total"},notRemovable:true,image:blankImage}
      const taxPriceItem = {summedAmount:taxAmount,description:"",amount:"",details:{name:"Tax"},notRemovable:true,image:blankImage}
      parsedShoppingCart = [...parsedShoppingCart,taxPriceItem,totalPriceItem]
      this.setState({shoppingCart:parsedShoppingCart,loading:false,totalCharged:totalCharged})
    }
    catch(err){
      console.log(err)
    }
  }
  async handleSubmit(event){
    event.preventDefault();
  }
  selectDiningChoice(diningChoice){
    this.setState({diningChoice:diningChoice})
  }
  renderDiningChoiceFlow(diningChoice){
    switch(diningChoice){
      case "delivery":
        return(
          <Fields
          user={this.props.user}
          fields={[
              {title:"First name",label:"firstName"},
              {title:"Last name",label:"lastName"},
              {title:"Address",label:"address1"},
              {title:"Apt, suite, or floor",label:"address2",required:false},
              {title:"City",label:"city"},
              {title:"State",label:"state"},
              {title:"Zip Code",label:"zipCode"},
              {title:"Phone number (XXXXXXXXXX)",label:"phoneNumber",pattern:'[0-9]{10}',type:"tel"},
              {title:"Email Address",label:"emailAddress",type:"emailAddress",required:false},
          ]}
          menuCheckout
          shoppingCart={this.state.shoppingCart}
          totalCharged={this.state.totalCharged}
          />
        )
      case "pick-up":
        return(
          <PickUpFields
          user={this.props.user}
          fields={[
              {title:"First name",label:"firstName"},
              {title:"Last name",label:"lastName"},
              {title:"Phone number (XXXXXXXXXX)",label:"phoneNumber",pattern:'[0-9]{10}',type:"tel"},
              {title:"Email Address",label:"emailAddress",type:"emailAddress",required:false},
          ]}
          menuCheckout
          shoppingCart={this.state.shoppingCart}
          totalCharged={this.state.totalCharged}
          />
        )
      default:
        return(
          <div/>
        )
    }
  }
  render(){
    if (this.state.loading){
      return(
        <>
        </>
      )
    }
    else{
      return(
        <section className="section section_menu section_border_bottom" id="section_reservation" style={{marginTop:30}}>
          <div className="container">
            <div className="row">
              <div className="col">

                <h2 className="section__heading text-center">
                Complete your checkout online
                </h2>

                <p className="section__subheading text-center">
                  Have your food ready for pick-up, indoor dining, or delivery
                </p>
              </div>
            </div>
            <ItemList
              shoppingCart={this.state.shoppingCart}
              setState={(props)=>this.setState(props)}
            />
            <div style={{alignItems:"center",justifyContent:"center",marginTop:40,width:"100%",display:"flex"}}>
            <div className="row" style={{width:"80vw"}}>
              <Button text="Delivery" style={{marginTop:10}} onClick={()=>this.selectDiningChoice("delivery")}/>
              {/*<Button text="InDoor/Outdoor Dining" style={{marginTop:10}} onClick={()=>this.selectDiningChoice("dine-in")}/>*/}
              <Button text="Pick-Up" style={{marginTop:10}} onClick={()=>this.selectDiningChoice("pick-up")}/>
            </div>
            </div>
            {this.renderDiningChoiceFlow(this.state.diningChoice)}
          </div>
        </section>
      )
    }
  }
}
