import React, { useState } from "react";
import "./theme.css";
import Button from "./components/button.js";

import IsoTopeGrid from "react-isotope";
import Masonry from 'react-masonry-component';
import ReactCardFlip from 'react-card-flip';
import SingleSelection from "./components/SingleSelection.js";
import MultiSelect from "react-multi-select-component";
import IconWithBadge from "./components/circleIconWithBadge";
import {Link, generatePath} from "react-router-dom";
import {calculateIfRestaurantIsOpen} from "./functions/js/simpleCalcs.js";

const fetchFixedOptions = (options) => {
  var correctOptions = [];
  options.map((option)=>{
    var newOption = {label:option.name+(option.price?` $${(option.price/100).toFixed(2)}`:""),value:option.name}
    correctOptions = [newOption,...correctOptions]
  })
  return correctOptions;
}

const cardsDefault = [
  {
    id: "a",
    filter: ["test", "chart"]
  },
  {
    id: "b",
    filter: ["test1", "tile"]
  },
  {
    id: "c",
    filter: ["test", "chart"]
  },
  {
    id: "d",
    filter: ["test1", "tile"]
  },
  {
    id: "e",
    filter: ["test", "tile"]
  },
  {
    id: "f",
    filter: ["test1", "chart"]
  },
  {
    id: "h",
    filter: ["test1", "chart"]
  }
];

const filtersDefault = [
  { label: "all", isChecked: true },
  { label: "test", isChecked: false },
  { label: "test1", isChecked: false },
  { label: "chart", isChecked: false },
  { label: "tile", isChecked: false }
];

export default class Menu extends React.Component{

  state = {
    selectedMenuIndex:0,
    isFlipped:{},
    selectedSubOptions:{},
    shoppingCart:[],
    orderedAmount:{},
    isOpen:calculateIfRestaurantIsOpen(this.props.user.openHours)
    // selectedMenuIndex:this.props.merchantMenus[0].name
  }

  setMenuIndex(index){
    this.setState({selectedMenuIndex:index})
  }

  flipCard(id){
    this.state.isFlipped[id] = !this.state.isFlipped[id]
    this.setState({isFlipped:this.state.isFlipped})
  }

  handleChange(id,name,selection){
    if (id in this.state.selectedSubOptions){
      this.state.selectedSubOptions[id][name]=selection;
    }
    else{
      this.state.selectedSubOptions[id] = { [name] : selection }
    }
    this.setState({selectedSubOptions:this.state.selectedSubOptions});
  }

  addToCart(menuItem){
    var finalizedOptions = [];
    var cartItem = {};
    cartItem.amount = (this.state.orderedAmount[menuItem.id]?this.state.orderedAmount[menuItem.id]:1);
    cartItem.details = {name:menuItem.name,description:menuItem.description};
    cartItem.category = this.props.merchantMenus[this.state.selectedMenuIndex].category;
    cartItem.summedAmount = parseInt(menuItem.price)*parseInt(cartItem.amount);
    if (menuItem.options){
      for (var i = 0;i<JSON.parse(menuItem.options).length;i++){
        var givenOption
        var currentOption = JSON.parse(menuItem.options)[i];
        if (menuItem.id in this.state.selectedSubOptions){
          // Something has been selected by the user, hence we are not selecting defaults
          if (!currentOption.allowMultipleSelections){
            if (currentOption.name in this.state.selectedSubOptions[menuItem.id]){
              // If option has been selected we find it and set it as the selected one
              var selectedSubOption = this.state.selectedSubOptions[menuItem.id][currentOption.name]
              givenOption = {name:currentOption.name,selectedSubOption:selectedSubOption}
            }
            else{
              // Something was selected in this menu item, but not this specific option so we are selecting the default instead
              givenOption={name:currentOption.name,selectedSubOption:currentOption.options[0]}
            }
          }
          else{
            givenOption = {name:currentOption.name,selectedSubOption:this.state.selectedSubOptions[menuItem.id][currentOption.name]}
          }
        }
        else{
          givenOption = {name:currentOption.name,selectedSubOption:currentOption.options[0]}
        }
        finalizedOptions = [...finalizedOptions,givenOption]
      }
      cartItem.options = finalizedOptions
    }
    this.state.shoppingCart = [...this.state.shoppingCart,cartItem];
    this.state.orderedAmount[menuItem.id]=1;
    this.state.isFlipped[menuItem.id]=false;
    this.setState({shoppingCart:this.state.shoppingCart,isFlipped:this.state.isFlipped,selectedAmount:this.state.selectedAmount});
  }

  addToSelectedSubOptions(id,item,selectedSubOption){
    console.log(selectedSubOption)
    var price = null
    for (var i =0;i<item.options.length;i++){
      console.log(item.options[i])
      if (item.options[i].name==selectedSubOption){
        price = item.options[i].price
      }
    }
    var givenOption = {}
    if (price){
      givenOption = {name:selectedSubOption,price:price}
    }
    else{
      givenOption = {name:selectedSubOption}
    }
    if (id in this.state.selectedSubOptions){
      this.state.selectedSubOptions[id][item.name]=givenOption
    }
    else{
      this.state.selectedSubOptions[id]={[item.name]:givenOption}
    }
  }

  render(){
    return (
      <section className="section section_menu section_border_bottom" id="section_menu">
      {(this.state.shoppingCart.length!==0)&&<a
        style={{backgroundColor:"#1F1F1F",borderRadius:25,width:200,height:50, alignItems:'center',justifyContent:'center',position:"fixed",zIndex:300,bottom:50,right:100,display:"flex",cursor:"pointer",flexDirection:"row",paddingTop:15}}
          href={generatePath("/checkout?cart=:shoppingCart",{shoppingCart:JSON.stringify(this.state.shoppingCart)})}
        >
        <p style={{color:"#777",textAlign:"center",alignSelf:"center",display:"flex",marginRight:10,position:'relative'}}>
          Checkout
        </p>
        <p>
        <IconWithBadge
          iconName="fas fa-shopping-cart"
          style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row",position:'relative'}}
          badgeValue={this.state.shoppingCart.length}
        />
        </p>
      </a>}
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="section__heading text-center" style={{marginTop:30}}>
                Our menu
              </h2>
              <p className="section__subheading text-center">
                {(this.state.isOpen)?"Only the best weed":"Sorry, our shop is currently closed. We're open everyday from 10AM to 8PM."}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {(this.state.isOpen)&&<nav className="section_menu__nav">
              <ul>
                {this.props.merchantMenus.map((f,i) => (
                  <li key={`${f.name}_key`} className={(i === this.state.selectedMenuIndex)?"active":""} onClick={()=>this.setMenuIndex(i)} id={`${f.name}_key`} >
                    <a style={{cursor:"pointer"}}>{f.name}</a>
                  </li>
                ))}
              </ul>
            </nav>}
            </div>
          </div>
            {(this.state.isOpen)&&<Masonry
            className="section_menu__grid"
            >
              {this.props.merchantMenus[this.state.selectedMenuIndex].menuItems.items.map((menuItem,index) => (
                <div key={menuItem.id} className={`col-md-6 section_menu__grid__item ${menuItem.filter}`}>
                <div class="section_menu__item">
                <ReactCardFlip isFlipped={this.state.isFlipped[menuItem.id]} flipDirection="vertical" className="row">
                  <div class="row" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    {(!this.props.hideImages)&&<div class="col-3 align-self-center">
                      <div class="section_menu__item__img">
                        <img src={`assets/img/${[25+(index%7)]}.jpg`} alt="..."/>
                      </div>
                    </div>}
                    <div class="col-7">
                      <h4>{menuItem.name}</h4>
                      <p>
                        {menuItem.description}
                      </p>
                      <Button text="Add to cart" onClick={()=>this.flipCard(menuItem.id)} style={{marginTop:10,marginBottom:10}}/>
                    </div>
                    <div class="col-2" style={{position:"absolute",top:0,right:5}}>
                      <div class="section_menu__item__price text-center">
                        ${(menuItem.price/100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div class="row" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    {(!this.props.hideImages)&&<div class="col-3 align-self-center">
                      <div class="section_menu__item__img">
                        <img src={`assets/img/${[25+(index%7)]}.jpg`} alt="..."/>
                      </div>
                    </div>}
                    <div class="col-7">
                      <h4>{menuItem.name}</h4>
                      <SingleSelection title="Amount"  options={[{name:"1"},{name:"2"},{name:"3"},{name:"4"},{name:"5"}]} onChange={(orderedAmount)=>{this.state.orderedAmount[menuItem.id]=orderedAmount.target.value;}}/>
                      {menuItem.options&&JSON.parse(menuItem.options).map((item)=>{
                        if (item.allowMultipleSelections){
                          var fixedOptions = fetchFixedOptions(item.options);
                          return(
                            <div>
                            <p>{item.name}</p>
                              <MultiSelect
                                labelledBy={"Select"}
                                options={fixedOptions}
                                multiple={true}
                                value={(this.state.selectedSubOptions[menuItem.id])?this.state.selectedSubOptions[menuItem.id][item.name]:undefined}
                                onChange={(selection)=>this.handleChange(menuItem.id,item.name,selection)}
                              />
                            </div>
                          )
                        }
                        else{
                          return(
                            <SingleSelection title={item.name} options={item.options} onChange={(selectedSubOption)=>this.addToSelectedSubOptions(menuItem.id,item,selectedSubOption.target.value)}/>
                          )
                        }
                      })}
                      <div class="row">
                        <Button text="Back" onClick={()=>this.flipCard(menuItem.id)} style={{marginTop:10}}/>
                        <Button text="Add" onClick={()=>this.addToCart(menuItem)} style={{marginTop:10}}/>
                      </div>
                    </div>
                    <div class="col-2" style={{position:"absolute",top:0,right:5}}>
                      <div class="section_menu__item__price text-center">
                        ${(menuItem.price/100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </ReactCardFlip>
                </div>
                </div>
              ))}
            </Masonry>}
          </div>
        </section>
    );
  }
}


// import logo from './logo.svg';
// import './App.css';
// import "./theme.css"
// // import {Menu} from './theme.js'
// import IsoTopeGrid from "react-isotope";
// import React, { useState } from "react";
//
// const filtersDefault = [
//   { label: "all", isChecked: true },
//   { label: "test", isChecked: false },
//   { label: "test1", isChecked: false },
//   { label: "chart", isChecked: false },
//   { label: "tile", isChecked: false }
// ];
//
// function App() {
//
//   const [filters, updateFilters] = useState(filtersDefault);
//
//   const onFilter = event => {
//     const {
//       target: { value, checked }
//     } = event;
//
//     updateFilters(state =>
//       state.map(f => {
//         if (f.label === value) {
//           return {
//             ...f,
//             isChecked: checked
//           };
//         }
//
//         return f;
//       })
//     );
//   };
//
//   return (
//     <>
//     <section class="section section_menu section_border_bottom">
//       {/*<div class="container">
//         <div class="row">
//           <div class="col">
//             <h2 class="section__heading text-center">
//               Our menu
//             </h2>
//             <p class="section__subheading text-center">
//               Good food is the foundation of happiness.
//             </p>
//
//           </div>
//         </div>
//         <div class="row">
//           <div class="col">
//             <nav class="section_menu__nav">
//               <ul>
//                 <li class="active">
//                   <a href="#menu_images" data-filter=".mains">Mains</a>
//                 </li>
//                 <li>
//                   <a href="#menu_images" data-filter=".lunch">Lunch</a>
//                 </li>
//                 <li>
//                   <a href="#menu_images" data-filter=".dinner">Dinner</a>
//                 </li>
//                 <li>
//                   <a href="#menu_images" data-filter=".drinks">Drinks</a>
//                 </li>
//               </ul>
//             </nav>
//
//           </div>
//         </div>
        // <div class="row section_menu__grid" id="menu_images">
        //   <div class="col-md-6 section_menu__grid__item lunch">
        //     <div class="section_menu__item">
        //       <div class="row">
        //         <div class="col-3 align-self-center">
        //           <div class="section_menu__item__img">
        //             <img src="assets/img/30.jpg" alt="..."/>
        //           </div>
        //         </div>
        //         <div class="col-7">
        //           <h4>Lorem ipsum dolor sit amet</h4>
        //           <p>
        //             Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi, obcaecati! Sapiente laudantium.
        //           </p>
        //         </div>
        //         <div class="col-2">
        //           <div class="section_menu__item__price text-center">
        //             $15
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
//           <div class="col-md-6 section_menu__grid__item mains">
//             <div class="section_menu__item">
//               <div class="row">
//                 <div class="col-3 align-self-center">
//                   <div class="section_menu__item__img">
//                     <img src="assets/img/30.jpg" alt="..."/>
//                   </div>
//                 </div>
//                 <div class="col-7">
//                   <h4>Lorem ipsum dolor sit amet</h4>
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi, obcaecati! Sapiente laudantium.
//                   </p>
//                 </div>
//                 <div class="col-2">
//                   <div class="section_menu__item__price text-center">
//                     $15
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="col-md-6 section_menu__grid__item dinner">
//             <div class="section_menu__item">
//               <div class="row">
//                 <div class="col-3 align-self-center">
//                   <div class="section_menu__item__img">
//                     <img src="assets/img/30.jpg" alt="..."/>
//                   </div>
//                 </div>
//                 <div class="col-7">
//                   <h4>Lorem ipsum dolor sit amet</h4>
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi, obcaecati! Sapiente laudantium.
//                   </p>
//                 </div>
//                 <div class="col-2">
//                   <div class="section_menu__item__price text-center">
//                     $15
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="col-md-6 section_menu__grid__item drinks">
//             <div class="section_menu__item">
//               <div class="row">
//                 <div class="col-3 align-self-center">
//                   <div class="section_menu__item__img">
//                     <img src="assets/img/30.jpg" alt="..."/>
//                   </div>
//                 </div>
//                 <div class="col-7">
//                   <h4>Lorem ipsum dolor sit amet</h4>
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi, obcaecati! Sapiente laudantium.
//                   </p>
//                 </div>
//                 <div class="col-2">
//                   <div class="section_menu__item__price text-center">
//                     $15
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>*/}
//     </section>
//     </>
//   );
// }
// export default App;
