import React, { useState } from "react";
import "../theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "../dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation} from "../mutations.js"
import ReactCardFlip from 'react-card-flip';
import { loadStripe } from '@stripe/stripe-js';

const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined
const stripePromise = loadStripe('pk_live_CdK2gBIuhN2YhzU6nnZZvPQ4');

export default class Fields extends React.Component{
  state={
  }
  paymentChoice(event,choice){
    event.preventDefault();
    // this.setState({isFlipped:!this.state.isFlipped});
    if (!this.props.deliveryQuote){
      // alert("Please wait for us to finish calculating the estimated delivery fee");
      return
    }
    else if (this.props.deliveryQuote.kind==="error"){
      // alert("Sorry that address is undeliverable, please try another one")
      return
    }
    switch(choice){
      case "stripe":
        console.log("Navigating Stripe")
        var amount
        if (this.props.deliveryQuote.kind==="pick-up"){
          var amount = (this.props.totalCharged)
        }
        else{
          var amount = (this.props.deliveryQuote.fee+this.props.totalCharged)
        }
        this.handleDonation(amount,"one-time")
        break
      case "kikt":
        console.log("Navigating to Kikt")
        break
    }
  }
  async fetchSessionId(amount,type){
    try{
      console.log("Fetching Session Id")
      const url = "https://zs600xh5p2.execute-api.us-east-1.amazonaws.com/Prod/process-stripe"
      const data = {
        amount:amount,
        type:type,
        pendingOrderId:this.props.pendingOrderId
      }
      const response = await fetch(url,{
        method: 'POST',
        cache:'no-cache',
        mode:'cors',
        body: JSON.stringify(data)
      })
        // .then(data => {
        //   console.log('Success:', data);
        // })
        // .catch((error) => {
        //   console.error('Error:', error);
        // })
      // console.log(response.body)
      const response_data = await response.json()
      return response_data["id"]
    }
    catch(err){
      console.log(err)
    }
    // return response["id"]
  }
  async handleDonation(amount,type){
    console.log("Sending Donation")
    this.setState({submittingCheckout:true})
    const intAmount = parseInt(amount)
    if (intAmount<=0){
      alert("Invalid amount inserted")
      return
    }
    else{
      const sessionId = await this.fetchSessionId(amount,type)
      const stripe = await stripePromise;

      await stripe.redirectToCheckout({
          sessionId: sessionId
        })
    }
    this.setState({submittingCheckout:false})
    console.log("Finished sending donation")
  }
  renderPrices(deliveryQuote){
    if (!deliveryQuote){
      return(
        <p class="section__subheading text-center">Fetching delivery details...</p>
      )
    }
    switch(deliveryQuote.kind){
      case "error":
        return(
          <p class="section__subheading text-center">Sorry, we cannot deliver to that address. Please enter a different address or choose pick-up.</p>
        )
      case "delivery_quote":
        return(
          <div>
            <p class="section__subheading text-center">Delivery fee: ${((deliveryQuote.fee)/100).toFixed(2)}</p>
            <p class="section__subheading text-center">ETA: {deliveryQuote.duration} minutes</p>
            <p class="section__subheading text-center">Total: ${((1.00875*(deliveryQuote.fee+this.props.totalCharged))/100).toFixed(2)}</p>
          </div>
        )
    }
  }
  render(){
    return(
      <div class="container">
        <div class="row">
          <div class="col">

            <h2 class="section__heading text-center">
            Choose your preferred Check-Out Method Below
            </h2>

            {(false)&&<p class="section__subheading text-center">
              Record your information for next time, get rewards, and save 4% when you pay through <a href="https://www.kikt.io"> Kikt </a>
            </p>}

            {this.renderPrices(this.props.deliveryQuote)}

          </div>

        </div>
        <div class="row justify-content-lg-center  section_reservation__row">
          <div class="col-lg-8">

            <form class="section_reservation__form" id="reservation__form">
              <div class="row">
                <div class="col-md-6" style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
                  <button type="submit" class="btn btn-primary" disabled={this.state.submittingCheckout} onClick={(event)=>this.paymentChoice(event,"stripe")} style={{marginTop:15}}>
                    {(this.state.submittingCheckout)?<div class="loader"></div>:"Credit/Debit"}
                  </button>
                </div>
                {/*<div class="col-md-6" style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
                <button type="submit" class="btn btn-primary" disabled={this.state.submitting} style={{marginTop:15}} onClick={(event)=>this.paymentChoice(event,"kikt")}>
                  {(this.state.submitting)?<div class="loader"></div>:"Kikt"}
                </button>
                </div>*/}
                <div class="col">
                  {this.state.madeReservation&&<div class="alert alert-success">
                    Congratulations you made a succesful reservation! You will receive a text message as soon as our staff confirms your spot.
                  </div>}
                  <div class="text-center" style={{marginTop:30,marginBottom:5}}>
                    <button type="submit" class="btn btn-primary" disabled={this.state.submitting} onClick={(event)=>{
                      event.preventDefault();
                      this.props.setState({isFlipped:false});
                    }}>
                      {(this.state.submitting)?<div class="loader"></div>:"Back"}
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    )
  }
}
