import React, { useState } from "react";
import Menu from './Menu';
import Amplify from '@aws-amplify/core'
import API, { graphqlOperation } from '@aws-amplify/api';
import {getMenu} from "./queries.js"
import {Parallax} from 'react-parallax'
import {handleNewStateValue} from './functions/js/main.js'
import Form from "./components/Form.js";
import Flickity from 'react-flickity-component'

export default class Home extends React.Component{
  render(){
      return(
        <>
        <Parallax className="section section_welcome" bgImage="https://unsplash.com/photos/NxRx7_mPdEk/download?ixid=MnwxMjA3fDB8MXxzZWFyY2h8NHx8bWFyaWp1YW5hfHwwfHx8fDE2MzkwNzk5MzA&force=true">
          <div class="section_welcome__main">
            <div class="container">
              <div class="row align-items-center">
                <div class="col">
                  <h2 class="section__preheading section_welcome__preheading text-center text-muted">
                    <span class="text-primary">THC</span> / New York
                  </h2>
                  <h1 class="section__heading section_welcome__heading text-center">
                    New York Blast
                  </h1>
                  <p class="section__subheading section_welcome__subheading text-center text-muted">
                    Blast away, New York style!
                  </p>

                  <div class="text-center">
                    <a href="#section_menu" class="btn btn-primary text-white">
                      Menu
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="section_welcome__footer">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md">

                  <ul class="section_welcome__footer__social text-center text-lg-left">
                    {/*<li>
                      <a href="https://www.facebook.com/brooklynlabelgreenpoint/">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>*/}
                    <li>
                      <a href="https://www.instagram.com/fuckyourmeaninglessbrand/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>

                </div>
                <div class="col-md">

                  <div class="section_welcome__footer__address text-center text-lg-right">
                    <i class="fas fa-map-marker-alt"></i><a href="https://goo.gl/maps/oe1DroERdBUZDciw6"> 180 Franklin St, Brooklyn, NY 11222 </a>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </Parallax>

        <section class="section section_discover section_no-padding_bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-2 align-self-start">

                <p class="section_discover__description">
                  New York, NY
                </p>

              </div>
              <div class="col-md-4 align-self-start">
                <div class="section_discover__img">
                  <img src="assets/img/2.jpg" class="img-fluid" alt="..."/>
                </div>
              </div>
              <div class="col-md-6 align-self-center">

                <h2><em/>Authentic New York</h2>
                <p>
                  New York Blast is grown in New York City. We keep it fresh. Make sure to try our NYC special.
                </p>

              </div>
            </div>
          </div>
        </section>

        <section class="section section_discover">
          <div class="container">
            <div class="row">
              <div class="col-md-2 order-md-2 align-self-end">

                <p class="section_discover__description">
                  Good Vibes
                </p>

              </div>
              <div class="col-md-4 order-md-3 align-self-start">

                <div class="section_discover__img alt">
                  <img src="https://unsplash.com/photos/jNVgCpQ0LhQ/download?ixid=MnwxMjA3fDB8MXxzZWFyY2h8M3x8bGF1Z2hpbmclMjBwZW9wbGV8fDB8fHx8MTYzOTA4Mjg0OA&force=true" class="img-fluid" alt="..."/>
                </div>

              </div>
              <div class="col-md-6 order-md-1 align-self-center">

                <h2> Feel Good About Your Weed </h2>
                <p>
                  When you take a hit of New York Blast, you can feel good about where it's coming from. A portion of our profits are distributed to historically disadvantaged communities.
                </p>

              </div>
            </div>
          </div>
        </section>
        <Menu merchantMenus={this.props.merchantMenus} hideImages user={this.props.user}/>
        {/*<Form
        user={this.props.user}
        fields={[
            {title:"First name",label:"firstName"},
            {title:"Last name",label:"lastName"},
            {title:"Phone number (XXXXXXXXXX)",label:"phoneNumber",pattern:'[0-9]{10}',type:"tel"},,
            {title:"Email Address",label:"emailAddress",type:"email"},
        ]}/>*/}
        <section class="section section_testimonials section_border_bottom">
          <div class="container">
            <div class="row">
              <div class="col">

                <h2 class="section__heading text-center">
                  What clients say about us
                </h2>

                <p class="section__subheading text-center">
                  Truly amazing weed
                </p>

              </div>
            </div>
            <div class="row">
              <div class="col">
                <Flickity class="section_testimonials__carousel">
                  <div class="section_testimonials__carousel__item text-center text-md-left">
                    <div class="row align-items-center">
                      <div class="col-md-3 order-md-3">

                        <div class="section_testimonials__photo">
                          <img src="assets/img/20.jpg" class="img-fluid" alt="..."/>
                        </div>

                      </div>
                      <div class="col-md-7 order-md-2">

                        <blockquote class="section_testimonials__blockquote mx-auto">
                          <p>
                            It's good. It's that simple. Pick what you like and you won't be dissapointed. I, personally, recommend the New York Special.
                          </p>
                          <footer class="text-muted">
                            Robert
                          </footer>
                        </blockquote>

                      </div>
                      <div class="col-md-1 order-md-1"></div>
                    </div>
                  </div>
                  <div class="section_testimonials__carousel__item text-center text-md-left">
                    <div class="row align-items-center">
                      <div class="col-md-3 order-md-3">

                        <div class="section_testimonials__photo">
                          <img src="assets/img/21.jpg" class="img-fluid" alt="..."/>
                        </div>

                      </div>
                      <div class="col-md-7 order-md-2">

                        <blockquote class="section_testimonials__blockquote mx-auto">
                          <p>
                            My date went super well thanks to the warm glow that the New York Blast provides. I always order from them. The Winter Glow and NY Speical are on point. I order from here often and I'm never disappointed. Try it out!
                          </p>
                          <footer class="text-muted">
                            Elisabeth
                          </footer>
                        </blockquote>

                      </div>
                      <div class="col-md-1 order-md-1"></div>
                    </div>
                  </div>
                  <div class="section_testimonials__carousel__item text-center text-md-left">
                    <div class="row align-items-center">
                      <div class="col-md-3 order-md-3">


                        <div class="section_testimonials__photo">
                          <img src="assets/img/22.jpg" class="img-fluid" alt="..."/>
                        </div>

                      </div>
                      <div class="col-md-7 order-md-2">

                        <blockquote class="section_testimonials__blockquote mx-auto">
                          <p>
                            I'm so happy I finally found a place that is easy to order from online and accessible. They even accept cards online! The delivery is fast and discreet.
                          </p>
                          <footer class="text-muted">
                            Jeannee
                          </footer>
                        </blockquote>

                      </div>
                      <div class="col-md-1 order-md-1"></div>
                    </div>
                  </div>
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        </>
      )
    }
}
