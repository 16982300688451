import React from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  generatePath
} from "react-router-dom";
import "./theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "./dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation,createPendingOrder,updatePendingOrder} from "./mutations.js"
import {getPendingOrder} from "./queries.js"
import {Parallax} from 'react-parallax'
import ItemList from "./components/ItemList.js"
import Button from "./components/button.js"
import Form from "./components/Form.js";
import Menu from "./Menu.js";
import Fields from "./components/Fields.js";

const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined

export default class Checkout extends React.Component{
  constructor(props){
    super(props);
  }
  async componentDidMount(){
    window.scrollTo(0, 0)
    const pendingOrderId = await ((new URLSearchParams(window.location.search)).get("id"))
    console.log(pendingOrderId);
    var res = await API.graphql(graphqlOperation(getPendingOrder,{id:pendingOrderId})).catch(err=>console.log(err))
    if (!res.data.getPendingOrder.approved){
      let input = {
        approved:true,
        id:pendingOrderId
      }
      res = await API.graphql(graphqlOperation(updatePendingOrder,{input})).catch(err=>console.log(err))
    }
  }
  render(){
      return(
        <section className="section section_menu section_border_bottom" id="section_reservation" style={{marginTop:30}}>
          <div className="container">
            <div className="row">
              <div className="col">

                <h2 className="section__heading text-center">
                Thank you for your payment!
                </h2>

                <p className="section__subheading text-center">
                  Please check your phone to see order updates
                </p>
              </div>
            </div>

            <div style={{alignItems:"center",justifyContent:"center",marginTop:40,width:"100%",display:"flex"}}>
            <div className="row" style={{width:"80vw"}}>

            </div>
            </div>
          </div>
        </section>
      )
  }
}
