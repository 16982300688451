import React, { useState } from "react";
import Amplify from '@aws-amplify/core'
import API, { graphqlOperation } from '@aws-amplify/api';
import {getMenu} from "./queries.js"
import {Parallax} from 'react-parallax'
import {handleNewStateValue} from './functions/js/main.js'
import Form from "./components/Form.js";
import Flickity from 'react-flickity-component'
import Home from "./Home.js";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Menu from "./Menu.js";
import ContactUs from "./components/ContactUs.js";
import AboutUs from "./components/AboutUs.js";
import Checkout from "./components/Checkout.js";
import SuccessPage from "./SuccessPage"

Amplify.configure({
  'aws_appsync_graphqlEndpoint': 'https://lttgaupuendhfcxve3k34bv5t4.appsync-api.us-east-1.amazonaws.com/graphql',
  'aws_appsync_region': 'us-east-1',
  'aws_appsync_authenticationType': 'API_KEY',
  'aws_appsync_apiKey':'da2-d2daeyru3neefpnkdwlcptuczy'
})

export default class Container extends React.Component{
  state = {
    loading:true
  }
  async componentDidMount(){
    var id = "625fe7e2-08ca-4492-bebd-cb016c437e72"
    var response
    try{
      response = await API.graphql(graphqlOperation(getMenu,{id}))
    }
    catch (e){
      console.log(e)
    }
    this.setState({loading:false,merchantMenus:response.data.getCustomer.merchantMenus.items,user:{id:response.data.getCustomer.id,openHours:response.data.getCustomer.openHours}})
    console.log(response)
  }
  render(){
    if (this.state.loading){
      return(
        <div>
        </div>
      )
    }
    else{
      return(
        <BrowserRouter>
          <Switch>
            <Route exact path="/menu">
              <Menu merchantMenus={this.state.merchantMenus} user={this.state.user}/>
            </Route>
            <Route exact path="/menu_no-images">
              <Menu merchantMenus={this.state.merchantMenus} user={this.state.user} hideImages={true}/>
            </Route>
            <Route exact path="/menu_no-images">
              <Menu merchantMenus={this.state.merchantMenus} user={this.state.user} hideImages={true}/>
            </Route>
            <Route exact path="/reservation">
              <Form user={this.props.user}
              fields={[
                  {title:"First name",label:"firstName"},
                  {title:"Last name",label:"lastName"},
                  {title:"Phone number (XXXXXXXXXX)",label:"phoneNumber",pattern:'[0-9]{10}',type:"tel"},,
                  {title:"Email Address",label:"emailAddress",type:"email"},
              ]}/>
            </Route>
            <Route exact path="/contact-us">
              <ContactUs user={this.props.user}
              fields={[
                  {title:"First name",label:"firstName"},
                  {title:"Last name",label:"lastName"},
                  {title:"Phone number (XXXXXXXXXX)",label:"phoneNumber",pattern:'[0-9]{10}',type:"tel"},,
                  {title:"Email Address",label:"emailAddress",type:"email"},
              ]}/>
            </Route>
            <Route exact path="/about-us">
              <AboutUs user={this.state.user} />
            </Route>
            <Route path="/checkout" exact>
              <Checkout user={this.state.user} {...this.props}/>
            </Route>
            <Route path="/success" exact>
              <SuccessPage user={this.state.user} {...this.props}/>
            </Route>
            <Route exact path="/" render={props=> <Home merchantMenus={this.state.merchantMenus} user={this.state.user} {...props}/>} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      )
    }
  }
}
