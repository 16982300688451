import React, { useState } from "react";
import "../theme.css";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import {SingleDatePicker} from 'react-dates';
import moment from 'moment'
import DayPickerSingleDateControllerWrapper from "../dates/DayPickerSingleDateControllerWrapper";
import API, { graphqlOperation } from '@aws-amplify/api';
import {createReservation} from "../mutations.js"
import {Parallax} from 'react-parallax'

const newDate = new Date;
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let today = `${month}-${date}-${year}`
let thisMinutes = newDate.getMinutes();
let thisHour = undefined

export default class Form extends React.Component{
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    reservationDate:Math.floor(new Date().now / 1000),
    reservationTime:thisHour,
    seatingArea:"Outdoors",
    numberOfPeople:1
  }
  async handleSubmit(event){
    event.preventDefault();
    this.setState({submitting:true})
    let {firstName,lastName,phoneNumber,emailAddress,numberOfPeople,reservationDate,reservationAddedSeconds} = this.state
    const requestedTimeStamp = parseInt(reservationDate)+parseInt(reservationAddedSeconds/1000)
    const input = {
      firstName:firstName,
      lastName:lastName,
      phoneNumber:"+1"+phoneNumber,
      emailAddress:emailAddress,
      numberOfPeople:numberOfPeople,
      confirmed:false,
      requestedTimeStamp:requestedTimeStamp,
      merchantId:this.props.user.id
    }
    console.log(input)
    var res = await API.graphql(graphqlOperation(createReservation,{input}))
    this.setState({submitting:false,madeReservation:true,firstName:"",lastName:"",phoneNumber:"",emailAddress:"",numberOfPeople:""})
    console.log(res)
  }
  handleSubmit(){
  }
  render(){
    return(
      <>
      <Parallax className="section section_header" bgImage="assets/img/37.jpg" style={{paddingTop:50,height:"30vh",width:"100vw"}}>
    		<div class="container">
    			<div class="row">
    				<div class="col">

    					<h1 class="section__heading section_header__heading text-center" style={{paddingTop:80,fontWeight:700,fontSize:"2.5rem"}}>
    						Contact Us
    					</h1>

    				</div>
    			</div>
    		</div>
    	</Parallax>
      <section class="section section_contact">
        <div class="container">
          <div class="row">
            <div class="col">

              <h2 class="section__heading text-center">
              Get in touch with us
              </h2>

              <p class="section__subheading text-center">
                Make an inquiry by calling or emailing us.
              </p>

            </div>

          </div>
          <div class="row">
            <div class="col-md-3 order-md-2">
              <div class="section_contact__info">
                  <div class="section_contact__info__item">
                    <h4 class="section_contact__info__item__heading">
                      Write us
                    </h4>
                    <p class="section_contact__info__item__content">
                      <a href="mailto:admin@domain.com">newyorkblast@kblack.design</a>
                    </p>
                  </div>
                  <div class="section_contact__info__item">
                    <h4 class="section_contact__info__item__heading">
                      Call us
                    </h4>
                    <p class="section_contact__info__item__content">
                      <a href="tel:+19876543210">+1 (720) 573-2717</a>
                    </p>
                  </div>
                  <div class="section_contact__info__item">
                    <h4 class="section_contact__info__item__heading">
                      Visit us
                    </h4>
                    <p class="section_contact__info__item__content">
                      180 Franklin St, Brooklyn, NY 11222
                    </p>
                  </div>
                  <div class="section_contact__info__item">
                    <h4 class="section_contact__info__item__heading">
                      Social links
                    </h4>
                    <ul class="section_contact__info__item__content">
                      <li>
                        <a href="https://www.facebook.com/brooklynlabelgreenpoint/">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/fuckyourmeaninglessbrand/">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      </>
    )
  }
}
