export const calculateNewPrice = (oldPrice,nativeEvent)=>{
  var newCalc = 0
  if (nativeEvent.key==="Backspace"){
    newCalc = parseInt(oldPrice/10)
  }
  else if (isNaN(nativeEvent.key)){
    newCalc = oldPrice
  }
  else{
    newCalc = ( oldPrice * 10 ) + parseInt(nativeEvent.key)
  }
  return newCalc;
}

export const getOptionsString = (item,hideMoney=false)=>{
  return (item.options).map(
  (subOption,subIndex)=>
    {
      return (subOption.name + ": "+
      (subOption.allowMultipleSelections)?
        (Object.values(subOption.selectedSubOption).map((singleChoice,singleChoiceIndex)=>{
          return(singleChoice.name+ ((!hideMoney)? (" $"+(singleChoice.price/100).toFixed(2)+"\n") : "") )
        }))
      :
        (subOption.selectedSubOption.name)
      +
    ( (subOption.selectedSubOption.price&&!hideMoney) ?
      " | +$"+ (subOption.selectedSubOption.price/100).toFixed(2)
      :
      ""
    )
     + (
       (subIndex === (item.options.length-1) )
        ?
        "\n"
          :
        "\n\n"))
      }
   )
}

const checkIfNumbersInObject = (object) =>{
  for (var i = 0;i<15;i++){
    if (i.toString() in object){
      return true
    }
  }
  return false
}

export const getCorrectOptionsString = (item,hideMoney=false)=>{
  try{
    var finalString = "";
    if (item.options!==undefined){
      for (var i = 0;i<item.options.length;i++){
        var subOption = item.options[i];
        finalString += subOption.name + ": ";
        // if (checkIfNumbersInObject(subOption.selectedSubOption)){
        //   // This code is never called... I think
        //   for (var j = 0;j<Object.values(subOption.selectedSubOption).length;j++){
        //     var singleChoice = Object.values(subOption.selectedSubOption)[j];
        //     finalString += (singleChoice.name);
        //     if (!hideMoney&&singleChoice.price){
        //       finalString += " $"+(singleChoice.price/100).toFixed(2)+"\n";
        //     }
        //     else{
        //       finalString+="\n"
        //     }
        //   }
        // }
        // else{
        if (subOption.selectedSubOption){
          if (subOption.selectedSubOption.name===undefined){
            // If .name is undefined it indicates that the suboption allows multiple selections
            if (Array.isArray(subOption.selectedSubOption)){
              for (var j = 0; j<subOption.selectedSubOption.length;j++){
                var singleChoice = subOption.selectedSubOption[j]
                finalString+=singleChoice.label+" "
              }
            }
            else{
              for (var j = 0;j<Object.values(subOption.selectedSubOption).length;j++){
                var singleChoice = Object.values(subOption.selectedSubOption)[j];
                finalString += (singleChoice.name);
                if (!hideMoney&&singleChoice.price){
                  finalString += " +$"+(singleChoice.price/100).toFixed(2)+"\n";
                }
                else{
                  finalString+="\n";
                }
              }
            }
          }
          else{
            finalString += (subOption.selectedSubOption.name);
            finalString += (!hideMoney&&subOption.selectedSubOption.price&&subOption.selectedSubOption.price) ? " +$"+(subOption.selectedSubOption.price/100).toFixed(2) : "";
          }
        }
          // console.log("ITEM OPTIONS STRING",item.options)
        // }
        if (i === item.options.length-1){
          finalString += "\n"
        }
        else{
          finalString += "\n\n"
        }
      }
    }
    return finalString
  }
  catch(err){
    console.log(err)
  }
}

export const sortAllMenusAlphabetically = (userDeets)=>{
  userDeets.merchantMenus.items.sort((a, b) => a.name.localeCompare(b.name));
  for (var i = 0; i < userDeets.merchantMenus.items.length;i++){
    userDeets.merchantMenus.items[i].menuItems.items.sort((a, b) => a.name.localeCompare(b.name))
  }
  return userDeets
}

export const getAllOptionPrices = (options) =>{
  var addedPrice =0
  console.log("THIS",options)
  for (var i = 0;i<options.length;i++){
    var currentOption = options[i];
    if (JSON.stringify(currentOption).indexOf("$") > -1){
      if (Array.isArray(currentOption.selectedSubOption)){
        for (var j = 0; j<currentOption.selectedSubOption.length;j++){
          var singleChoice = currentOption.selectedSubOption[j];
          if (singleChoice.label.indexOf("$")>-1){
            var moneyIndex = singleChoice.label.indexOf("$")
            var cost = parseFloat(singleChoice.label.substring(moneyIndex+1,moneyIndex+5)*100)
            addedPrice+=cost
          }
        }
      }
    }
    else if (currentOption.selectedSubOption){
      if (currentOption.selectedSubOption.price){
        addedPrice+= currentOption.selectedSubOption.price
      }
    }
  }
  return addedPrice
}

export const calculateIfRestaurantIsOpen = (openHours) => {
  var restaurantNotOpen = false;
  const parsedHours = JSON.parse(openHours);
  const date = new Date();
  const theDay = date.getDay();
  const theHour = date.getHours();
  if (theDay in parsedHours){
    var openHoursOnTheDay = parsedHours[theDay.toString()];
    for (var i =0;i<openHoursOnTheDay.length;i++){
      var shift = openHoursOnTheDay[i];
      var startTime = parseInt(shift[0])
      var endTime = parseInt(shift[1])
      if ( (theHour >= startTime) && (theHour < endTime) ){
        var restaurantOpen = true
        return restaurantOpen
      }
    }
  }
  return restaurantNotOpen
}

export const getShoppingCartWithoutImages = (shoppingCart) => {
  var newCart = []
  var newObject
  for (var i = 0; i < shoppingCart.length;i++){
    var cartItem = shoppingCart[i];
    newObject = {
      amount:cartItem.amount,
      summedAmount:cartItem.summedAmount,
      details:{
        name:cartItem.details.name,
        description:cartItem.details.description
      },
      category:cartItem.category,
      options:cartItem.options
    }
    newCart=[...newCart,newObject]
  }
  return newCart
}
